export const initialState = {
  hasDarkBackground: false,
  isMenuOpen: false,
  isCompanyModalOpen: false,
  companyModalData: null,
  colorChangingBackgroundActive: false,
  // isApplyBannerVisible: true,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setHasDarkBackground':
      return {
        ...state,
        ...action.updates,
      }

    case 'setColorChangingBackgroundActive':
      return {
        ...state,
        colorChangingBackgroundActive: action.updates.isActive,
        hasDarkBackground: action.updates.isActive,
      }

    case 'toggleMenuOpen':
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
      }

    case 'closeMenu':
      return {
        ...state,
        isMenuOpen: false,
      }

    case 'openCompanyModal':
      return {
        ...state,
        companyModalData: action.updates.data,
        isCompanyModalOpen: true,
      }

    case 'closeCompanyModal':
      return {
        ...state,
        companyModalData: null,
        isCompanyModalOpen: false,
      }

    case 'setApplyBannerVisible':
      return {
        ...state,
        ...action.updates,
      }

    default:
      return state
  }
}
