// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-career-js": () => import("./../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-js": () => import("./../../src/pages/investment.js" /* webpackChunkName: "component---src-pages-investment-js" */),
  "component---src-pages-philosophy-js": () => import("./../../src/pages/philosophy.js" /* webpackChunkName: "component---src-pages-philosophy-js" */),
  "component---src-pages-portfolio-js": () => import("./../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-team-js": () => import("./../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-case-study-js": () => import("./../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-news-js": () => import("./../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-person-js": () => import("./../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-text-js": () => import("./../../src/templates/text.js" /* webpackChunkName: "component---src-templates-text-js" */)
}

