const linkResolver = ({ type, uid, filter, ...doc } = {}) => {
  const lang = (doc.lang || '').split('-')[0]

  // console.log('linkResolver', { type, uid, ...doc })

  switch (type) {
    case 'career':
      return `/${lang}/career`

    case 'person':
      return `/${lang}/person/${uid}`

    case 'news':
      return `/${lang}/news/${uid}`

    case 'case_study':
      return `/${lang}/case/${uid}`

    case 'investment':
      return `/${lang}/investment`

    case 'team':
      return `/${lang}/team`

    case 'philosophy':
      return `/${lang}/philosophy`

    case 'portfolio':
      return `/${lang}/portfolio`

    case 'news_archive':
      switch (filter) {
        case 'insights':
          return `/${lang}/archive/insights`

        case 'updates':
          return `/${lang}/archive/updates`

        case 'events':
          return `/${lang}/archive/events`

        default:
          return `/${lang}/archive`
      }

    case 'contact':
      return `/${lang}/contact`

    case 'home':
      return `/${lang}`

    default:
      return `/${lang}/${uid}`
  }
}

module.exports = {
  linkResolver,
}
