import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
import classNames from 'classnames'

import css from './styles.module.scss'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const Hamburger = () => {
  const [{ isMenuOpen }] = useContext(StateContext)

  return (
    <div
      className={classNames(css.overlay, { [css.isMenuOpen]: isMenuOpen })}
      aria-hidden
    />
  )
}

export default Hamburger
