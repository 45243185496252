import React, { useContext, useCallback } from 'react'
import { Link } from 'gatsby'

import css from './styles.module.scss'
import { StateContext } from '../../context/GlobalStateContext'
import { ReactComponent as LogoSvg } from '../../images/logo.svg'
import { ReactComponent as WhiteLogoSvg } from '../../images/logo-white.svg'

// component
// ==========================================================================================
const Logo = () => {
  const homeLink = '/'
  const [{ hasDarkBackground, isMenuOpen }, dispatch] = useContext(StateContext)
  const handleMenuClick = useCallback(() => {
    if (window.innerWidth < 768 && isMenuOpen) {
      setTimeout(() => {
        dispatch({ type: 'toggleMenuOpen' })
      }, 100)
    }
  }, [isMenuOpen, dispatch])

  return (
    <section className={css.wrap}>
      <Link
        to={homeLink}
        className={css.link}
        onClick={handleMenuClick}
        title="logo link"
      >
        {hasDarkBackground && !isMenuOpen ? (
          <WhiteLogoSvg className={css.svg} />
        ) : (
          <LogoSvg className={css.svg} />
        )}
      </Link>
    </section>
  )
}

export default Logo
