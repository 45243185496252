import React, { useContext, useCallback } from 'react'
import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import css from './styles.module.scss'
import useTranslation from '../../hooks/useTranslation'
import { prismicLocales } from '../../translations/config'
import Link from '../Link'
import { StateContext } from '../../context/GlobalStateContext'
import { ReactComponent as Arrow } from '../../images/arrow-right-big.svg'

// data
// ==========================================================================================
const query = graphql`
  query MenuQuery {
    prismic {
      data: allNavigations {
        edges {
          node {
            meta: _meta {
              id
              lang
              alternateLanguages {
                uid
                lang
                type
                id
              }
            }
            menu: main_menu {
              label
              link {
                ... on PRISMIC__Document {
                  meta: _meta {
                    id
                    type
                    uid
                    lang
                  }
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

// component
// ==========================================================================================
const Menu = ({ edges } = {}) => {
  const { locale } = useTranslation()
  const [{ isMenuOpen, hasDarkBackground }, dispatch] = useContext(StateContext)
  const handleMenuClick = useCallback(() => {
    if (window.innerWidth < 768) {
      setTimeout(() => {
        dispatch({ type: 'toggleMenuOpen' })
      }, 100)
    }
  }, [dispatch])
  const edge = edges.find(
    ({ node }) => node.meta.lang === prismicLocales(locale)
  )
  if (!edge) {
    return null
  }
  const links = edge.node.menu.filter(
    ({ link, label }) => (link?.meta || link?.url) && label
  )
  const linkCount = links.length

  return (
    <section
      className={classNames(css.menuWrap, {
        [css.isMenuOpen]: isMenuOpen,
        [css.onDark]: hasDarkBackground,
      })}
    >
      <nav>
        {links.map(({ link, label }, i) => (
          <span className={css.linkWrap} key={i}>
            <Link
              link={link}
              className={css.link}
              key={link.meta?.id || link.url}
              onClick={handleMenuClick}
            >
              {label}
            </Link>
            {linkCount === i + 1 && <Arrow className={css.arrow} />}
          </span>
        ))}
      </nav>
    </section>
  )
}

// Menu.propTypes = {
//   menu: PropTypes.arrayOf(
//     PropTypes.shape({
//       link: PropTypes.shape({}).isRequired,
//     })
//   ).isRequired,

//   // lang: PropTypes.string,
// }

// Menu.defaultProps = {
//   lang: "en-gb",
//   meta: [],
//   title: "Page",
//   description: "",
//   image: undefined,
// }

// static query
// ==========================================================================================
const MenuWithQuery = (props) => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={query}
    render={withPreview(
      ({ prismic: { data } }) => (
        <Menu {...data} {...props} />
      ),
      query
    )}
  />
)

export default MenuWithQuery
