import React, { useEffect, useState, useRef } from 'react'
import { defaultLocale } from '../translations/config'

export const LocaleContext = React.createContext({
  locale: defaultLocale,
  setLocale: () => null,
})

export const LocaleProvider = ({ initialLanguage, children }) => {
  const [locale, setLocale] = useState(initialLanguage)
  const isClient = typeof window !== 'undefined'
  const prevLocale = useRef('first_time')

  // get locale from url and see if it matches current state. if not change state
  useEffect(() => {
    if (isClient) {
      const localeChanged = prevLocale.current !== locale

      // update cookie banner when language changes
      try {
        if (typeof window?.usercentrics?.updateLanguage === 'function') {
          window.usercentrics.updateLanguage(locale)
        }
      } catch (err) {
        console.log(err)
      }
      // mannually update html tag with new language
      document.documentElement.lang = locale
      // set language pref for future visits
      localStorage.setItem('locale', locale)
      // set html tag language
      document.documentElement.lang = locale

      if (prevLocale.current !== 'first_time' && localeChanged) {
        window.location.reload()
      }
      prevLocale.current = locale
    }
  }, [locale])

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}
