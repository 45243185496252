import React, { useCallback, useContext } from 'react'
import { Link } from 'gatsby'
import { get } from 'lodash'

import css from './styles.module.scss'
import { locales } from '../../translations/config'
import { getLocaleFromUrl } from '../../translations/utils'
import { LocaleContext } from '../../context/LocaleContext'
import { linkResolver } from '../../utils/linkResolver'

const LocaleSwitcher = ({ meta }) => {
  const { locale, setLocale } = useContext(LocaleContext)
  const otherLang = get(meta, 'alternateLanguages[0]')
  const handleLocaleChange = useCallback(
    (event) => {
      const lang = getLocaleFromUrl(event.target.href)
      setLocale(lang)
    },
    [setLocale]
  )

  return (
    <div className={css.wrap}>
      {locales.map((loc, i) => (
        <React.Fragment key={loc}>
          {i > 0 && <span className={css.separator}>/</span>}
          {loc !== locale ? (
            <Link
              to={otherLang ? linkResolver(otherLang) : `/${loc}`}
              onClick={handleLocaleChange}
              className={css.link}
            >
              {loc}
            </Link>
          ) : (
            <span className={css.active}>{loc}</span>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default LocaleSwitcher
