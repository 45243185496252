import React from 'react'

import css from './styles.module.scss'
import NewsItem from '../NewsItem'

// component
// ==========================================================================================
const NewsList = ({ articles, removeLastUnderline }) => {
  return (
    <section className={css.wrap}>
      {articles.map(({ node } = {}) => (
        <NewsItem
          {...node}
          key={node?.meta?.id}
          removeLastUnderline={removeLastUnderline}
        />
      ))}
    </section>
  )
}

export default NewsList
