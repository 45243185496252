import { defaultLocale, locales } from './config'

export function isLocale(tested) {
  return locales.some((locale) => locale === tested)
}

export function getInitialLocale() {
  const isClient = typeof window !== 'undefined'
  if (!isClient) {
    return defaultLocale
  }

  const localSetting = localStorage.getItem('locale')
  if (localSetting && isLocale(localSetting)) {
    return localSetting
  }

  const [browserSetting] = navigator.language.split('-')
  if (isLocale(browserSetting)) {
    return browserSetting
  }

  return defaultLocale
}

export function getLocaleFromUrl(url) {
  if (url) {
    // TODO: look for better solution. this is fragile
    return url.split('/')[3]
  }
  if (typeof window !== 'undefined') {
    const matched = window.location.pathname.match(/^\/(\w+)/)
    return matched ? matched[1] : defaultLocale
  }
  return defaultLocale
}
