import React from 'react'

import MenuItems from './MenuItems'
import Hamburger from './Hamburger'
import Overlay from './Overlay'
import MenuCloser from './MenuCloser'

// component
// ==========================================================================================
const Menu = () => {
  return (
    <>
      <Hamburger />
      <MenuItems />
      <Overlay />
      <MenuCloser />
    </>
  )
}

export default Menu
