module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ibbventures","defaultLang":"de-de","langs":["en-gb","de-de"],"shortenUrlLangs":false,"path":"/preview","previews":true,"omitPrismicScript":true,"pages":[{"type":"News","match":"/:lang?/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/news.js","langs":["en-gb","de-de"]},{"type":"Text","match":"/:lang?/:uid","path":"/text","component":"/opt/build/repo/src/templates/text.js","langs":["en-gb","de-de"]},{"type":"Person","match":"/:lang?/:uid","path":"/person","component":"/opt/build/repo/src/templates/person.js","langs":["en-gb","de-de"]},{"type":"Case_study","match":"/:lang?/:uid","path":"/caseStudy","component":"/opt/build/repo/src/templates/caseStudy.js","langs":["en-gb","de-de"]}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Venture capital for Berlin | IBB Ventures","short_name":"IBB Ventures","start_url":"/","background_color":"#000","theme_color":"#000","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"911609a346ae4dfec454bda4c59e189b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
