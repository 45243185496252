import React from 'react'
import Helmet from 'react-helmet'

import useTranslation from '../hooks/useTranslation'
import { prismicLocales } from '../translations/config'

// component
// ==========================================================================================
const Head = ({
  edges,
  metaTitle,
  metaDescription,
  metaImage,
  meta,
  ...props
}) => {
  const { locale } = useTranslation()
  const edge = edges?.find(
    ({ node } = {}) => node.meta.lang === prismicLocales(locale)
  )
  // if (!edge) {
  //   return null
  // }
  const { title, description, image, companyName } =
    (edge && edge.node) || props
  const pageTitle = metaTitle || title
  const pageDescription = metaDescription || description
  const pageImage = metaImage || image || {}
  const titleTemplate = companyName ? `%s | ${companyName}` : `%s`

  return (
    <Helmet
      htmlAttributes={{ lang: locale }}
      title={pageTitle}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: 'viewport',
          content:
            'width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no, viewport-fit=cover',
        },
        { name: `description`, content: pageDescription },
        { property: `og:title`, content: pageTitle },
        { property: `og:description`, content: pageDescription },
        { property: `og:type`, content: `website` },
        { property: `og:image`, content: pageImage.url },
        { property: `og:image:width`, content: pageImage.dimensions?.width },
        { property: `og:image:height`, content: pageImage.dimensions?.height },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: pageTitle },
        { name: `twitter:description`, content: pageDescription },
      ]}
      link={[]}
    />
  )
}

export default Head
