import React from 'react'
import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import css from './styles.module.scss'
import useTranslation from '../../hooks/useTranslation'
import { prismicLocales } from '../../translations/config'
import LocaleSwitcher from '../LocaleSwitcher'
import FullWidthBackground from '../FullWidthBackground'
import NewsletterForm from '../NewsletterForm'
import SocialLinks from '../SocialLinks'
import Copyright from './Copyright'
import Navigation from './Navigation'

// data
// ==========================================================================================
const query = graphql`
  query FooterQuery($lang: String) {
    prismic {
      data: allFooters(lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              lang
              alternateLanguages {
                uid
                lang
                type
                id
              }
            }
            fundingCta: funding_cta
            fundingLink: funding_link {
              ... on PRISMIC__Document {
                meta: _meta {
                  id
                  type
                  uid
                  lang
                }
              }
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            newsletterHeading: newsletter_heading
            newsletterPlaceholder: newsletter_placeholder
            newsletterDisclaimer: newsletter_disclaimer
            newsletterSendCta: newsletter_send_cta
            newsletterFormSuccess: newsletter_form_success
            newsletterFormFail: newsletter_form_fail
            copyright
            berlinMade: berlin_made
            menu {
              label
              link {
                ... on PRISMIC__Document {
                  meta: _meta {
                    id
                    type
                    uid
                    lang
                  }
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

// component
// ==========================================================================================
const Footer = ({ edges, pageProps } = {}) => {
  const { locale } = useTranslation()
  const edge =
    edges.find(({ node }) => node.meta.lang === prismicLocales(locale)) || {}

  return (
    <footer
      className={classNames(css.wrap, {
        [css.noBorder]: pageProps.noFooterBorder,
      })}
    >
      <FullWidthBackground isLight />
      <div className="row">
        <div className="col-sm-12 col-md-10 col-lg-3 offset-md-1">
          <NewsletterForm {...edge.node} />
        </div>

        <div className="col-sm-6 col-md-10 col-lg-6 offset-md-1">
          <div className={css.navWrap}>
            <Navigation {...edge.node} />
            <div className={css.localeSwitch}>
              <LocaleSwitcher {...pageProps} />
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-10 offset-md-1">
          <div className={css.socialWrap}>
            <SocialLinks className={css.socialLinks} hideTitle />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-10 offset-md-1">
          <Copyright {...edge.node} />
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  edges: [],
  pageProps: {},
}

// static query
// ==========================================================================================
const FooterWithQuery = (pageProps) => (
  <StaticQuery
    // waiting for bug to be resolved https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    query={query}
    render={withPreview(
      ({ prismic: { data, config } }) => (
        <Footer {...data} config={config} pageProps={pageProps} />
      ),
      query
    )}
  />
)

export default FooterWithQuery
