import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import { linkResolver } from '../utils/linkResolver'

// component
// ==========================================================================================
const LinkComponent = ({ link, children, className, onClick }) => {
  const linkProps = { className, onClick }

  if (!link) {
    return null
  }

  // external links
  if (link.url) {
    return (
      <OutboundLink
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </OutboundLink>
    )
  }

  return link.meta ? (
    <Link
      to={linkResolver(link.meta)}
      key={link.meta.id}
      activeClassName="active"
      {...linkProps}
    >
      {children}
    </Link>
  ) : null
}

export default LinkComponent
