import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const HeaderBackground = ({ isPinned }) => {
  const [reveal, setReveal] = useState(false)
  const [{ hasDarkBackground }] = useContext(StateContext)

  useEffect(() => {
    setTimeout(() => {
      setReveal(true)
    }, 0)
  }, [])

  return (
    <>
      {reveal && (
        <div
          className={classNames('background', css.background, {
            [css.sticky]: isPinned,
            [css.darkBg]: hasDarkBackground,
          })}
        />
      )}
    </>
  )
}

export default HeaderBackground
