import React from 'react'

import css from './styles.module.scss'

// component
// ==========================================================================================
const Copyright = ({ copyright, berlinMade }) => {
  const dateRegex = /\{\{\s?year\s?\}\}/
  const currentYear = new Date().getFullYear()

  if (!copyright) {
    return null
  }

  return (
    <div className={css.copyright}>
      <span>{copyright.replace(dateRegex, currentYear)}</span>
      <span>{berlinMade}</span>
    </div>
  )
}

export default Copyright
