import React from 'react'
import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'

import css from './styles.module.scss'
import useTranslation from '../../hooks/useTranslation'
import { prismicLocales } from '../../translations/config'
import Link from '../Link'

// data
// ==========================================================================================
const query = graphql`
  query SocialLinksQuery {
    prismic {
      data: allConfigs {
        edges {
          node {
            meta: _meta {
              id
              lang
              alternateLanguages {
                uid
                lang
                type
                id
              }
            }
            socialHeading: social_heading
            social {
              label
              link {
                ... on PRISMIC__Document {
                  meta: _meta {
                    id
                    type
                    uid
                    lang
                  }
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

// component
// ==========================================================================================
const SocialLinks = ({ edges, hideTitle, className }) => {
  const { locale } = useTranslation()
  const edge = edges.find(
    ({ node }) => node.meta.lang === prismicLocales(locale)
  )
  if (!edge) {
    return null
  }
  const { socialHeading, social } = edge.node
  const links = social.filter(({ label, link }) => label && link)

  return (
    <>
      {!hideTitle && (
        <h5 className={classNames('small-title', css.sectionTitle)}>
          {socialHeading}
        </h5>
      )}

      {links.map(({ label, link }) => (
        <div className={classNames(css.linkWrap, className)} key={label}>
          <Link link={link}>{label}</Link>
        </div>
      ))}
    </>
  )
}

SocialLinks.defaultProps = {
  edges: [],
}

// static query
// ==========================================================================================
const SocialLinksWithQuery = (pageProps) => (
  <StaticQuery
    query={query}
    render={withPreview(
      ({ prismic: { data } }) => (
        <SocialLinks {...data} {...pageProps} />
      ),
      query
    )}
  />
)

export default SocialLinksWithQuery
