import React from 'react'
import { Link } from 'gatsby'

import css from './styles.module.scss'
import { linkResolver } from '../../utils/linkResolver'

// component
// ==========================================================================================
const Navigation = ({ menu = [] }) => {
  const links = menu.filter(({ link, label }) => link?.meta && label)

  if (!links) {
    return null
  }

  return (
    <nav className={css.nav}>
      {links.map(({ link, label }) => (
        <div className={css.navLinkWrap} key={link.meta.id}>
          <Link to={linkResolver(link.meta)}>{label}</Link>
        </div>
      ))}
    </nav>
  )
}

export default Navigation
