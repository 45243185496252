import React from 'react'
import { navigate } from 'gatsby'
import { registerLinkResolver } from 'gatsby-source-prismic-graphql'
import Modal from 'react-modal'

import { linkResolver } from './src/utils/linkResolver'
import { LocaleProvider } from './src/context/LocaleContext'
import { StateProvider } from './src/context/GlobalStateContext'
import { initialState, reducer } from './src/store/global'
import { getInitialLocale, getLocaleFromUrl } from './src/translations/utils'
import Layout from './src/Layout'
import Header from './src/components/Header'

// const transitionDelay = 250
export const onClientEntry = async () => {
  // add polyfill for IE11
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

/**
 * We register `linkResolver` here for preview setup
 */
registerLinkResolver(linkResolver)

export const wrapRootElement = ({ element }) => {
  const initialLocale = getInitialLocale()
  const urlLanguage = getLocaleFromUrl()
  // check this here so redirect is only based on first page rather than every route change
  const shouldRedirect = initialLocale !== urlLanguage
  // assume hoome from route
  const isHomepage =
    window.location.pathname.replace(/\/+$/, '').split('/').length < 3
  const locale = isHomepage && shouldRedirect ? initialLocale : urlLanguage
  // if on home page and lanugage doesnt match browser then redirect
  if (isHomepage && shouldRedirect) {
    navigate(`/${initialLocale}`, { replace: true })
  }

  return (
    <LocaleProvider initialLanguage={locale}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <Header />
        {element}
      </StateProvider>
    </LocaleProvider>
  )
}

// wrap page element in layout to control page transitions
export const wrapPageElement = ({ element, props }) => {
  window.scrollTo([0, 0])
  return <Layout {...props}>{element}</Layout>
}

export const onInitialClientRender = () => {
  // window.scrollTo(0, 0)
  console.log('ReactDOM.render has executed')
  Modal.setAppElement('#___gatsby')

  // set general dom listeners to adjust button focus
  // Let the document know when the mouse is being used
  document.body.addEventListener('mousedown', function () {
    document.body.classList.add('using-mouse')
  })
  // Re-enable focus styling when Tab is pressed
  document.body.addEventListener('keydown', function (event) {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse')
    }
  })
}

// hijack scroll to prevent jump to top of page when out animation starts
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   if (location.action === 'PUSH') {
//     setTimeout(() => {
//       window.scrollTo(0, 0)
//     }, transitionDelay)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     setTimeout(() => {
//       window.scrollTo(...(savedPosition || [0, 0]))
//     }, transitionDelay)
//   }
//   return false
// }
