import config from '../../config'

const {
  cms: { langs, defaultLang },
  langNames,
} = config

const convertToShortCode = (locale = '') => locale.split('-')[0]

export const defaultLocale = convertToShortCode(defaultLang) || 'de'

export const locales = (langs || []).map(locale => convertToShortCode(locale))

export const prismicLocales = locale =>
  (langs || []).find(item => convertToShortCode(item) === locale)

export const languageNames = langNames
