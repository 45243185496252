/* eslint-disable react/no-array-index-key */
import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import { asText } from '../../utils/prismic'
import Link from '../Link'
import useTranslation from '../../hooks/useTranslation'

// component
// ==========================================================================================
const CompanyInfo = ({ manager, website, details, social, isHorizontal }) => {
  const { t } = useTranslation()
  const filteredSocial = (social || []).filter(
    ({ label, link }) => label && link
  )

  return (
    <dl className={classNames({ [css.isHorizontal]: isHorizontal })}>
      {manager?.meta && (
        <div className={css.listItem}>
          <dt className={classNames('small-title', css.listItemTitle)}>
            {t('inversmentManagerHeading')}
          </dt>
          <dd className={css.listItemDescription}>
            <Link link={manager}>{asText(manager.name)}</Link>
          </dd>
        </div>
      )}
      {website?.url && (
        <div className={css.listItem}>
          <dt className={classNames('small-title', css.listItemTitle)}>
            {t('websiteHeading')}
          </dt>
          <dd className={css.listItemDescription}>
            <Link link={website}>
              {website.url
                .replace(/^https?:\/\/(www\.)?/i, '')
                .replace(/\/$/i, '')}
            </Link>
          </dd>
        </div>
      )}
      {details?.map((item) => (
        <div className={css.listItem} key={item.heading}>
          <dt className={classNames('small-title', css.listItemTitle)}>
            {item.heading}
          </dt>
          <dd className={css.listItemDescription}>{item.body}</dd>
        </div>
      ))}
      {!!filteredSocial.length && (
        <div className={css.listItem}>
          <dt className={classNames('small-title', css.listItemTitle)}>
            {t('socialMediaHeading')}
          </dt>
          <dd className={css.listItemDescription}>
            {filteredSocial.map(({ link, label }, i) => (
              <Link link={link} key={i} className={css.socialLink}>
                {label}
              </Link>
            ))}
          </dd>
        </div>
      )}
    </dl>
  )
}

export default CompanyInfo
