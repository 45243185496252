import React, { useContext, useCallback } from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Image from '../Image'
import Link from '../Link'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const NewsItem = ({
  meta,
  type,
  title,
  author,
  eventDate,
  photo,
  photoSharp,
  externalLink,
  removeLastUnderline,
}) => {
  const isExternal = externalLink?.url
  const link = isExternal ? externalLink : { meta }
  const [, dispatch] = useContext(StateContext)
  // this component is also used in company modals so close modal when routing
  const closeModal = useCallback(() => {
    dispatch({ type: 'closeCompanyModal' })
  }, [])

  return (
    <article
      className={classNames(css.wrap, {
        [css.removeLastUnderline]: removeLastUnderline,
      })}
    >
      <Link link={link} className={css.link} onClick={closeModal}>
        <div className={classNames(css.row, 'row')}>
          <div className="col-sm-12 col-md-4 offset-md-1 order-md-2">
            <div className={css.imageWrap}>
              <Image
                image={photo}
                {...photoSharp}
                className={classNames(css.image, {
                  [css.nonSharpImage]: !photoSharp,
                })}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-5 offset-md-1 order-md-1">
            <div className={css.type}>{type}</div>
            <Title
              tag="h4"
              content={title}
              className={classNames(css.title, { 'out-arrow': isExternal })}
            />
            {type?.toLowerCase() === 'event'
              ? eventDate && (
                  <div className={classNames(css.eventData, 'biggest')}>
                    {eventDate}
                  </div>
                )
              : author && <div className={css.author}>By {author}</div>}
          </div>
        </div>
      </Link>
    </article>
  )
}

export default NewsItem
