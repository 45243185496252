const strings = {
  en: {
    // ui
    close: 'close',
    // portfolio
    new: 'NEW',
    showMore: 'Show more',
    showLess: 'Hide',
    // team page
    filterAll: 'All',
    // person page
    inversmentManagerHeading: 'Investment manager',
    websiteHeading: 'Website',
    socialMediaHeading: 'Social media',
    viewDetails: 'view details',
    investedCompaniesHeading: 'Invested Companies',
    // archive page
    showMorePagination: 'Show more',
    noMorePagination: 'End of the list',
    // industries
    Operations: 'Operations',
    'Software & IT': 'Software & IT',
    'Consumer & Digital': 'Consumer & Digital',
    'Industrial Technologies': 'Industrial Technologies',
    'Health Care': 'Healthcare',
  },

  de: {
    // ui
    close: 'schließen',
    // portfolio
    new: 'NEU',
    showMore: 'Zeig mehr',
    showLess: 'Ausblenden',
    // team page
    filterAll: 'Alle',
    // person page
    inversmentManagerHeading: 'Investment Manager',
    websiteHeading: 'Webseite',
    socialMediaHeading: 'Sozialen Medien',
    viewDetails: 'details anzeigen',
    investedCompaniesHeading: 'Investierte Unternehmen',
    // archive page
    showMorePagination: 'Zeig mehr',
    noMorePagination: 'Ende der Liste',
    // industries
    Operations: 'Operations',
    'Software & IT': 'Software & IT',
    'Consumer & Digital': 'Consumer & Digital',
    'Industrial Technologies': 'Industrial Technologies',
    'Health Care': 'Healthcare',
  },
}

export default strings
