import React, { useCallback, useState, useRef } from 'react'
import classNames from 'classnames'
import axios from 'axios'

import css from './styles.module.scss'
import Wysiwyg from '../Wysiwyg'

const formAPI = '/.netlify/functions/mailchimp-signup'

function formHandler(email) {
  const data = {
    email,
  }
  return axios({
    method: 'post',
    url: formAPI,
    data,
  })
}

function noOp() {
  console.log('submission in progress')
}

// component
// ==========================================================================================
const NewsletterForm = ({
  newsletterHeading,
  newsletterPlaceholder,
  newsletterDisclaimer,
  newsletterSendCta,
  newsletterFormSuccess,
  newsletterFormFail,
}) => {
  const [formState, setFormState] = useState(null)
  const inputRef = useRef(null)

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    const email = inputRef.current.value

    setFormState('loading')

    formHandler(email)
      .then(() => {
        inputRef.current.value = ''
        setFormState('success')
      })
      .catch((error) => {
        console.error(error)
        setFormState('error')
      })
  }, [])

  return (
    <section className={css.newsletterWrap}>
      <h5 className={classNames('small-title', css.sectionTitle)}>
        {newsletterHeading}
      </h5>
      <form
        onSubmit={formState === 'loading' ? noOp : handleSubmit}
        id="login-form"
      >
        <div className={css.formInner}>
          <input
            type="email"
            name="email_address"
            id="email_address"
            placeholder={newsletterPlaceholder}
            className={css.input}
            ref={inputRef}
            required
          />
          <button type="submit" className={css.button}>
            {formState === 'loading' ? '…' : newsletterSendCta}
          </button>
        </div>
      </form>
      <div className={css.feedback}>
        {formState === 'success' && newsletterFormSuccess}
        {formState === 'error' && newsletterFormFail}
      </div>
      <div className={css.disclaimer}>
        <Wysiwyg content={newsletterDisclaimer} />
      </div>
    </section>
  )
}

export default NewsletterForm
