import React, { useContext, useCallback } from 'react'
// import PropTypes from 'prop-types'
import HamburgerMenu from 'react-hamburger-menu'
import classNames from 'classnames'

import css from './styles.module.scss'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const Hamburger = () => {
  const [{ isMenuOpen, hasDarkBackground }, dispatch] = useContext(StateContext)
  const handleToggleMenu = useCallback(() => {
    dispatch({ type: 'toggleMenuOpen' })
  }, [dispatch])

  return (
    <div
      className={classNames(css.hamburgerWrap, {
        [css.isMenuOpen]: isMenuOpen,
      })}
    >
      <HamburgerMenu
        isOpen={isMenuOpen}
        menuClicked={handleToggleMenu}
        width={25}
        height={15}
        strokeWidth={1}
        rotate={0}
        color={hasDarkBackground && !isMenuOpen ? '#fff' : '#001a5a'}
        borderRadius={0}
        animationDuration={0.35}
      />
    </div>
  )
}

export default Hamburger
