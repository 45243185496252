import { useRef, useEffect, useContext, useCallback } from 'react'

import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const MenuCloser = () => {
  const mediaQueryList = useRef()
  const [{ isMenuOpen }, dispatch] = useContext(StateContext)
  const handleResize = useCallback(() => {
    if (isMenuOpen && mediaQueryList.current.matches) {
      dispatch({ type: 'closeMenu' })
    }
  }, [isMenuOpen, dispatch])
  // prevent body scroll when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
    return () => {
      document.body.classList.remove('noscroll')
    }
  }, [isMenuOpen])
  // close menu when going into non-mobile breakpoint
  useEffect(() => {
    mediaQueryList.current = window.matchMedia('(min-width: 768px)')
    window.addEventListener('resize', handleResize, { passive: true })
    // cleanup fn
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return null
}

export default MenuCloser
