import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'

// component
// ==========================================================================================
const FullWidthBackground = ({ className, isLight, isFixed }) => {
  return (
    <div
      className={classNames(css.wrap, className, {
        [css.isLight]: isLight,
        [css.isFixed]: isFixed,
      })}
    />
  )
}

export default FullWidthBackground
