import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import Helmet from 'react-helmet'
import { get } from 'lodash'

import '../styles/main.scss'
import css from './styles.module.scss'
import Head from '../components/Head'
// import CookieConsent from '../containers/CookieConsent'
// import TestGrid from '../components/TestGrid'
// import Header from '../components/Header'
import Footer from '../components/Footer'
import { StateContext } from '../context/GlobalStateContext'
import Modal from '../components/CompanyModal'
import BrowserWarning from '../components/BrowserWarning'

const enterDuration = 0.7
const exitDuration = 0.25

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: enterDuration,
      delay: exitDuration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: exitDuration },
  },
}

const Layout = ({ children, location, pageContext, ...props }) => {
  let pageType = pageContext.type || 'home'
  if (!pageContext.type && !pageContext.rootQuery) {
    pageType = '404'
  }
  const data = get(props, 'data.prismic.data.edges[0].node')
  const hasDarkBackground = ['home', 'portfolio'].indexOf(pageType) > -1
  const noFooterBorder = ['portfolio'].indexOf(pageType) > -1
  const noApplyCta = ['investment'].indexOf(pageType) > -1
  const [, dispatch] = useContext(StateContext)

  useEffect(() => {
    dispatch({
      type: 'setHasDarkBackground',
      updates: { hasDarkBackground },
    })
  }, [dispatch, hasDarkBackground])

  // implement smooth scrolling polyfill for safari
  useEffect(() => {
    if (
      getComputedStyle(document.documentElement).scrollBehavior !== 'smooth'
    ) {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]', { offset: 90 })
    }
  }, [])

  return (
    <div className={classNames('container', css.wrap)}>
      {/* {process.env.GATSBY_ENV === 'development' && <TestGrid />} */}
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NPRTPM84');`}
        </script>
        {/* OLD, all external scripts are now added via GTM
        <script
          async
          id="usercentrics-cmp"
          data-eu-mode="true"
          data-settings-id="vePZFXSs"
          src="https://app.eu.usercentrics.eu/browser-ui/latest/loader.js"
        />
        <script
          type="text/plain"
          data-usercentrics="Google Analytics 4"
          async
          src=" https://www.googletagmanager.com/gtag/js?id=G-G7R5K3V0CL"
        />
        <script data-usercentrics="Google Analytics 4">
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-G7R5K3V0CL', { 'anonymize_ip': true });
          `}
        </script>
        */}
      </Helmet>
      <Head {...data} />
      <noscript>
        <iframe
          title="gtm-iframe"
          src="https://www.googletagmanager.com/ns.html?id=GTM-NPRTPM84"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
         />
      </noscript>
      {/* <Header /> */}
      {/* <CookieConsent /> */}
      <BrowserWarning />

      {/* <AnimatePresence> */}
      <motion.main
        key={location.pathname}
        className={css.main}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.main>
      {/* </AnimatePresence> */}

      <Modal />

      <Footer {...data} {...{ noFooterBorder, noApplyCta }} />
    </div>
  )
}

export default Layout
