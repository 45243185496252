import React, { useState, useEffect } from 'react'

import css from './styles.module.scss'
import useTranslation from '../../hooks/useTranslation'

// component
// ==========================================================================================
const BrowserWarning = () => {
  const { t } = useTranslation()
  const [isIE, setIsIE] = useState(false)

  useEffect(() => {
    if (
      navigator.userAgent.indexOf('MSIE') > -1 ||
      navigator.appVersion.indexOf('Trident/') > -1
    ) {
      setIsIE(true)
    }
  }, [])

  return isIE ? (
    <div className={css.wrap}>
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-10 offset-lg-1">{t('filterAll')}</div> */}
          <div className="col-lg-10 offset-lg-1">
            We're sorry, but this browser is not supported by this website.
            Please use a modern web browser or contact us for support at{' '}
            <a href="mailto:venture@ibb-bet.de">venture@ibb-bet.de</a>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default BrowserWarning
