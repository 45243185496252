import React, { useContext, useCallback, useState } from 'react'
import classNames from 'classnames'
import Headroom from 'react-headroom'

import css from './styles.module.scss'
import Menu from '../Menu'
import Logo from '../Logo'
import HeaderBackground from '../HeaderBackground'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const Header = () => {
  const [{ isMenuOpen }] = useContext(StateContext)
  const [isPinned, setIsPinned] = useState(false)
  const handlePin = useCallback(() => {
    setIsPinned(true)
  }, [])
  const handleUnpin = useCallback(() => {
    setIsPinned(false)
  }, [])

  return (
    <section className="container">
      <Headroom
        disableInlineStyles
        wrapperStyle={{}}
        onPin={handlePin}
        onUnpin={handleUnpin}
        onUnfix={handleUnpin}
      >
        <div className={classNames(css.wrap)}>
          <HeaderBackground {...{ isPinned }} />
          <div className={classNames(css.inner, { [css.open]: isMenuOpen })}>
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <Logo />
              </div>
              <div className="col-sm-12 col-md-9">
                <Menu />
              </div>
            </div>
          </div>
        </div>
      </Headroom>
    </section>
  )
}

export default Header
