/* eslint-disable camelcase */
import React, { useRef, useContext, useCallback } from 'react'
import Modal from 'react-modal'

import css from './styles.module.scss'
import { asText } from '../../utils/prismic'
import { ReactComponent as CloseSvg } from '../../images/close.svg'
import Image from '../Image'
import Wysiwyg from '../Wysiwyg'
import CompanyInfo from '../CompanyInfo'
import NewsList from '../NewsList'
import useTranslation from '../../hooks/useTranslation'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const CompanyModal = () => {
  const { t } = useTranslation()
  const overlay = {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 26, 90, 0.9)',
  }
  const [{ companyModalData, isCompanyModalOpen }, dispatch] = useContext(
    StateContext
  )
  const data = companyModalData
  const closeModal = useCallback(() => {
    const y = window.scrollY
    // take action out of render loop to allow for state to change first
    setTimeout(() => {
      window.scrollTo(0, y)
    }, 0)
    dispatch({ type: 'closeCompanyModal' })
  }, [dispatch])

  return (
    <Modal
      isOpen={isCompanyModalOpen}
      onRequestClose={closeModal}
      style={{ overlay }}
      className={css.modal}
    >
      {data && (
        <div className="container">
          <button onClick={closeModal} type="button" className={css.close}>
            <span className="sr-only">{t('close')}</span>
            <CloseSvg />
          </button>
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-1">
              <Image image={data.logo} className={css.logo} />
              <div className={css.titleWarp}>
                <h3>{asText(data.name)}</h3>
              </div>
              <div className={css.bodyWarp}>
                <Wysiwyg content={data.body} />
              </div>
            </div>
            <div className="col-sm-12 col-md-3 offset-md-1">
              <CompanyInfo {...data} />
            </div>
          </div>
          {data.related?.length > 0 && (
            <div className={css.newsWrap}>
              <div className="row">
                <div className="col-sm-12">
                  <NewsList articles={data.related.slice(0, 5)} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  )
}

CompanyModal.defaultProps = {
  data: { meta: { id: '' } },
}

export default CompanyModal
